<template>
  <div class="personDetail">
    <div v-html="form" class="text" v-if="list.length>0"></div>
    <div class="details" v-for="i in list" :key="i.id" v-if="list.length>0">
      <van-cell :title="i.title" is-link @click="btnList(i)">
        <template #icon>
          <img class="icon" :src="i.coverPhoto" alt="">
        </template>
      </van-cell>
    </div>
    <div v-if="list.length<1">
      <nodata></nodata>
    </div>
    <goPage v-if="$store.state.tyPhone == 'ios'"></goPage>
  </div>
</template>
<script>
import {getSceneContentByColumnId ,getSceneContentDetail} from '@/api/home.js'

export default {
  name:"personDetail",
  data () {
    return {
      form:'',
      list:[]
    }
  },
  created() {
    this.init()
  },
  computed:{
    topTittle(){
      return this.$store.state.topTittle
    }
  },
  methods: {
    async init(){
      if(this.$store.state.jxbApp){
          window.location.href=`ssptitle://${this.topTittle}`
        }else{
          document.title = this.topTittle
        }
      const data = await getSceneContentByColumnId({
          "columnId": this.$route.query.columnId,
          "pageNumber": 1,
          "pageSize": 90071992,
          "title": ""
        })
      if(data.data.data.rows.length>0){
        if(data.data.data.rows[0].content){
          this.form = data.data.data.rows[0].content.replace(/<img/g, '<img style="max-width: 100%;"')
        }
        let arr = data.data.data.rows
        arr.shift()
        this.list = arr;
      }
    },
    btnList(res){
      localStorage.setItem('tittle',res.title)
      if(res.detailType == 1){
        if(res.type == 1){
          this.$router.push({path:"/scContent",query:{typeId:1,id:res.id}})
        }else if(res.type == 2){
          this.$router.push({path:"/scContent",query:{typeId:2,id:res.id}})
        }else{
          this.$router.push({path:"/scContent",query:{typeId:3,id:res.id}})
        }
      }else{
        this.$router.push({path:"/scContent",query:{typeId:4,id:res.id}})
      }
    }
  },
}
</script>
<style lang="less" scoped>
  .personDetail{
    position: relative;
    .text{
      margin-bottom: .729rem;
    }
    .details{
      
      .tittle{
        color: #000000;
        font-size: .498rem;
        font-weight: 400;
        margin-left: .453rem;
      }
      .icon{
      width: .596rem;
      height: .596rem;
      margin-right: .204rem;
      }
    }
    /deep/.van-cell::after{
    border-bottom:none
    }
    /deep/.van-cell{
      border-bottom: .009rem solid #ebedf0;
      align-items: center;
      .van-cell__title, .van-cell__value{
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
</style>